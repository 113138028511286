import React, { ReactElement, useEffect, useState } from "react";
import { navigate } from "gatsby";
import styled from "styled-components";

import NavBar from "../../components/NavBar";
import PasswordRecoveryForm from "../../components/PasswordRecoveryForm";
import { PasswordRecoveryFormValues } from "../../types";
import { restorePasswordRequest } from "../../services/customers";
import { CustomizedDialogs } from "../../components/Footer/Modal";
import iconLock from "../../assets/lock.svg";

export const PageContainer = styled.div`
  min-height: 100vh;
  padding-top: 4rem;
  background-color: #f6f9fa;
  overflow: hidden;

  .navbar {
    margin-top: 0;
  }

  @media (max-width: 992px) {
    padding-top: 0;
  }
`;

export const ContentContainer = styled.div`
  position: relative;
  width: 555px;
  margin-top: 100px;
  margin-bottom: 32px;
  color: #263242;

  @media (max-width: 992px) {
    margin-top: 64px;
    margin-bottom: 0;
    padding-bottom: 430px;
  }

  @media (max-width: 576px) {
    width: 100%;
    margin-top: 32px;
    padding-bottom: 300px;
  }
`;

export const Image = styled.img`
  position: absolute;
  top: 0;
  right: -110%;

  @media (max-width: 992px) {
    top: auto;
    right: 50%;
    bottom: 0;

    transform: translateX(50%);
  }

  @media (max-width: 576px) {
    width: 280px;
  }
`;

export const PageTitle = styled.h1`
  margin-top: 0;
  margin-bottom: 24px;
  font-size: 48px;
  font-weight: bold;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  @media (max-width: 576px) {
    font-size: 28px;
    font-weight: bold;
    line-height: 1.32;
  }
`;

export const PageDescription = styled.p`
  margin-top: 0;
  margin-bottom: 32px;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.33;

  @media (max-width: 576px) {
    font-size: 16px;
    line-height: 1.31;
  }
`;

export const Email = styled.span`
  color: #263242;
  font-weight: bold;
`;

export const Footer = styled.footer`
  padding-top: 50px;
  padding-bottom: 50px;

  a {
    color: #526df2;

    &:first-child {
      margin-left: 0;
    }
  }

  @media (max-width: 992px) {
    padding-bottom: 10px;
  }

  @media (max-width: 576px) {
    padding-bottom: 0;
  }
`;

function PasswordRecoveryPage(): ReactElement {
  const [data, setData] = useState<{
    token: string;
    email: string;
  }>();

  useEffect(() => {
    if (URL && location) {
      const params = new URL(location.href).searchParams;
      const token = params.get("token");
      const email = params.get("email");

      if (!token || !email) {
        navigate("/");
      } else {
        setData({
          token,
          email,
        });
      }
    }
  }, []);

  const onSubmit = async (values: PasswordRecoveryFormValues) => {
    if (data?.token) {
      try {
        const res = await restorePasswordRequest({
          password: values.passwordRepeat,
          token: data.token,
        });

        navigate(
          `/password-recovery/success/${location.search}${
            res.customer.firstname ? `&firstName=${res.customer.firstname}` : ""
          }`
        );
      } catch (error) {
        navigate(`/password-recovery/expired/`);
      }
    } else {
      navigate("/");
    }
  };

  return (
    <>
      <NavBar onlyLogo />
      <PageContainer>
        <div className="container">
          <ContentContainer>
            <PageTitle>Neues Passwort</PageTitle>
            <PageDescription>
              Ich möchte ein neues Passwort für <Email>{data?.email}</Email>{" "}
              festlegen.
            </PageDescription>
            <PasswordRecoveryForm onSubmit={onSubmit} />
            <Image src={iconLock} />
          </ContentContainer>
          <Footer>
            <CustomizedDialogs />
          </Footer>
        </div>
      </PageContainer>
    </>
  );
}

export default PasswordRecoveryPage;
